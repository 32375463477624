$count: 8;

#root {
  .m {
    &-t {
      @for $i from 0 through $count {
        &-#{$i * 8} {
          margin-top: floor(($i * 8) * 1px);
        }
      }
      &-a {
        margin-top: auto;
      }
    }
    &-b {
      @for $i from 0 through $count {
        &-#{$i * 8} {
          margin-bottom: floor(($i * 8) * 1px);
        }
      }
      &-a {
        margin-bottom: auto;
      }
    }
    &-r {
      @for $i from 0 through $count {
        &-#{$i * 8} {
          margin-right: floor(($i * 8) * 1px);
        }
      }
      &-a {
        margin-right: auto;
      }
    }
    &-l {
      @for $i from 0 through $count {
        &-#{$i * 8} {
          margin-left: floor(($i * 8) * 1px);
        }
      }
      &-a {
        margin-left: auto;
      }
    }
  }
}
