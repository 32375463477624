$fonts: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

body {
  margin: 0;
  color: #4c4c4c;
  font-family: $fonts;
}
small {
  font-size: 1em;
  font-weight: 400;
}
input, button, textarea {
  font-family: $fonts;
}
